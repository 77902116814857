import * as actionTypes from "./actions/actionTypes"

const initialState = {
  currentParty: {
    partyName: "-------",
    partyCategory: "-------",
    partyCategoryData: null,
    partyLocation: "-------",
    partyLocationData: null,
    partyDate: 0,
    savedSuppliers: null,
  },
  savingSupplierMessage: {},
  hasGeneratedSuppliers: false,
}
const party = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PARTY_CURRENT_CHANGE:
      return {
        ...state,
        currentParty: action.party,
      }
    case actionTypes.PARTY_HAS_GENERATED_SUPPLIERS:
      return { ...state, hasGeneratedSuppliers: action.hasGeneratedSuppliers }
    case actionTypes.PARTY_SAVED_SUPPLIERS_CHANGE:
      return { ...state, savingSupplierMessage: action.message }
    case actionTypes.PARTY_CLEAR:
      return { ...state, currentParty: null }
    default:
      return state
  }
}

export default party
