import { Auth } from "aws-amplify"
import * as actionTypes from "./actionTypes"
import lambdas from "@data/lambdas"

const { PARTY_GET } = lambdas[process.env.NEXT_PUBLIC_ENV]

export const partyCurrentChange = (party) => ({
  type: actionTypes.PARTY_CURRENT_CHANGE,
  party,
})

export const partyGeneratedSuppliersChange = (hasGeneratedSuppliers) => ({
  type: actionTypes.PARTY_HAS_GENERATED_SUPPLIERS,
  hasGeneratedSuppliers,
})

export const partySaveMessageUpdate = (message) => ({
  type: actionTypes.PARTY_SAVED_SUPPLIERS_CHANGE,
  message,
})

export const partyClearing = () => ({
  type: actionTypes.PARTY_CLEAR,
})

export const partyClear = () => async (dispatch) => {
  await dispatch(partyClearing())
}

export const partyUpdate = (party) => (dispatch) => {
  dispatch(partyCurrentChange(party))
}

export const partyLoadFirst = () => async (dispatch) => {
  try {
    const user = await Auth.currentSession()
    const response = await fetch(PARTY_GET, {
      method: "POST",
      body: JSON.stringify({
        sessionToken: user.getIdToken().getJwtToken(),
      }),
    })
    if (response.ok) {
      const data = await response.json()
      dispatch(partyCurrentChange(data.data.Items[0]))
    } else {
      console.error(response)
    }
  } catch (error) {
    console.warn(error)
  }
}
