export const AUTH_START = "AUTH_START"
export const AUTH_SUCCESS = "AUTH_SUCCESS"
export const AUTH_FAIL = "AUTH_FAIL"
export const AUTH_END = "AUTH_END"
export const AUTH_LOCK_SITE = "AUTH_LOCK_SITE"

export const PARTY_HAS_GENERATED_SUPPLIERS = "PARTY_HAS_GENERATED_SUPPLIERS"
export const PARTY_CURRENT_CHANGE = "PARTY_CURRENT_CHANGE"
export const PARTY_SAVED_SUPPLIERS_CHANGE = "PARTY_SAVED_SUPPLIERS_CHANGE"
export const PARTY_CLEAR = "PARTY_CLEAR"

export const DATA_LOAD = "DATA_LOAD"

export const BROWSER_LOCATION_LOADED = "BROWSER_LOCATION_LOADED"
export const PARAM_LOAD = "PARAM_LOAD"
export const PARAM_PRODUCT_LOAD = "PARAM_PRODUCT_LOAD"
export const PARAM_MOODBOARD_LOAD = "PARAM_MOODBOARD_LOAD"
export const SELECT_MOODBOARD_LOAD = "SELECT_MOODBOARD_LOAD"
export const IMAGES_SELECTED_MOODBOARD_LOAD = "IMAGES_SELECTED_MOODBOARD_LOAD"
export const PARAM_LOADED = "PARAM_LOADED"
