/* eslint-disable object-shorthand */
import React from "react"
import Script from "next/script"

const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_UAID
const IS_PRODUCTION_BROWSER = process.env.NEXT_PUBLIC_ENV === "production" && process.browser

export const pageViewGtag = (url) => {
  if (window.gtag) {
    window.gtag("config", GA_TRACKING_ID, {
      page_path: url,
    })
  }
}

export const eventGtag = ({ action, params }) => {
  if (window.gtag) {
    window.gtag("event", action, params)
  }
}

export const OutboundLink = (props) => {
  const { eventCategory, eventAction, eventLabel, eventValue, children, ...rest } = props

  const handleClick = (e) => {
    if (typeof props.onClick === `function`) {
      props.onClick(e)
    }

    let redirect = true

    if (e.button !== 0 || e.altKey || e.ctrlKey || e.metaKey || e.shiftKey || e.defaultPrevented) {
      redirect = false
    }

    if (props.target && props.target.toLowerCase() !== `_self`) {
      redirect = false
    }

    if (window.gtag) {
      eventGtag({
        action: eventAction || `click`,
        params: {
          event_category: eventCategory || `Outbound Link`,
          event_label: eventLabel || props.href,
          value: eventValue,
          event_callback: function () {
            if (redirect) {
              document.location = props.href
            }
          },
        },
      })
    } else {
      if (redirect) {
        document.location = props.href
      }
    }

    return false
  }

  return (
    <a {...rest} onClick={handleClick}>
      {children}
    </a>
  )
}

const ScriptGtag = ({ enableTracking }) => {
  if (!IS_PRODUCTION_BROWSER || !GA_TRACKING_ID) {
    return null
  }
  return enableTracking ? (
    <>
      <Script strategy="afterInteractive" src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`} />
      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GA_TRACKING_ID}', {
                page_path: window.location.pathname,
            });
          `,
        }}
      />
    </>
  ) : null
}

export default ScriptGtag
