import * as actionTypes from "./actionTypes"

export const loadBrowserLocation = () => ({
  type: actionTypes.BROWSER_LOCATION_LOADED,
  hasLoadedBrowserLocation: true,
})

export const paramLoad = (parameters) => ({
  type: actionTypes.PARAM_LOAD,
  parameters,
})

export const paramProductLoad = (paramProduct) => ({
  type: actionTypes.PARAM_PRODUCT_LOAD,
  paramProduct,
})

export const paramMoodboardLoad = (paramMoodboard) => ({
  type: actionTypes.PARAM_MOODBOARD_LOAD,
  paramMoodboard,
})

export const selectMoodboardLoad = (selectMoodboard) => ({
  type: actionTypes.SELECT_MOODBOARD_LOAD,
  selectMoodboard,
})

export const imagesSelectedMoodboardLoad = (imagesSelectedMoodboard) => ({
  type: actionTypes.IMAGES_SELECTED_MOODBOARD_LOAD,
  imagesSelectedMoodboard,
})

export const paramHasLoaded = () => ({
  type: actionTypes.PARAM_LOADED,
  isUpdated: true,
})
