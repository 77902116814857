import Amplify from "aws-amplify"
import React from "react"
import { Provider } from "react-redux"
import { applyMiddleware, compose, createStore } from "redux"
import thunk from "redux-thunk"
import AuthWrapper from "./AuthWrapper"
import rootReducer from "./index"

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: process.env.NEXT_PUBLIC_AWS_REGION,
    userPoolId: process.env.NEXT_PUBLIC_AWS_COGNITO_USER_POOL,
    identityPoolId: process.env.NEXT_PUBLIC_AWS_COGNITO_IDENTITY_POOL_ID,
    userPoolWebClientId: process.env.NEXT_PUBLIC_AWS_COGNITO_APP_CLIENT_ID,
    oauth: {
      domain: process.env.NEXT_PUBLIC_DOMAIN_OAUTH,
      scope: ["phone", "email", "profile", "openid", "aws.cognito.signin.user.admin"],
      redirectSignIn: process.env.NEXT_PUBLIC_DOMAIN,
      redirectSignOut: process.env.NEXT_PUBLIC_DOMAIN,
      responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
  Storage: {
    region: process.env.NEXT_PUBLIC_AWS_REGION,
    bucket: process.env.NEXT_PUBLIC_AWS_S3_BUCKET,
    identityPoolId: process.env.NEXT_PUBLIC_AWS_COGNITO_IDENTITY_POOL_ID,
  },
})

const loadDevTools = () =>
  typeof window !== "undefined" && window.devToolsExtension
    ? window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    : (f) => f

const store = createStore(rootReducer, compose(applyMiddleware(thunk), loadDevTools()))

const ReduxWrapper = ({ children }) => (
  <Provider store={store}>
    <AuthWrapper>{children}</AuthWrapper>
  </Provider>
)

export default ReduxWrapper
