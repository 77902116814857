import { Auth } from "aws-amplify"
import { useRouter } from "next/router"
import * as actionTypes from "./actionTypes"
import trackUser from "@lib/trackUser"

export const authStart = () => ({
  type: actionTypes.AUTH_START,
})

export const authSuccess = (attributes, groups) => {
  trackUser("countSession")

  return {
    type: actionTypes.AUTH_SUCCESS,
    attributes,
    groups,
  }
}

export const authFail = (error) => ({
  type: actionTypes.AUTH_FAIL,
  authData: error,
})

export const authEnd = () => ({
  type: actionTypes.AUTH_END,
})

export const authCheck = () => async (dispatch) => {
  dispatch(authStart())
  try {
    const user = await Auth.currentAuthenticatedUser({
      bypassCache: true,
    })
    const { attributes } = user
    const groups = user.signInUserSession.accessToken.payload["cognito:groups"]
    dispatch(authSuccess(attributes, groups))
  } catch (err) {
    if (err !== "not authenticated") {
      console.log("authCheck error")
      console.log(err)
    }
    dispatch(authFail(err))
  }
}

export const socialLogin =
  (provider, customState = "") =>
  async (dispatch) => {
    dispatch(authStart())
    try {
      let params = { provider }
      params = customState ? { ...params, customState } : params
      await Auth.federatedSignIn(params)
    } catch (err) {
      console.log(err)
      dispatch(authFail(err))
    }
  }

export const login =
  (email, password, callback = null) =>
  async (dispatch) => {
    dispatch(authStart())
    try {
      const result = await Auth.signIn(email, password)
      if (result.username) {
        console.log("login success")
        console.log(callback)
        if (callback) {
          await callback()
        }
        const user = await Auth.currentAuthenticatedUser()
        const { attributes } = user
        const groups = user.signInUserSession.accessToken.payload["cognito:groups"]

        dispatch(authSuccess(attributes, groups))
      } else {
        dispatch(authFail(result.message))
      }
      return result
    } catch (err) {
      console.log(err)
      dispatch(authFail(err))
      return err
    }
  }

export const partyClearing = () => ({
  type: actionTypes.PARTY_CLEAR,
})

export const signOut =
  (navigateHome = true) =>
  async (dispatch) => {
    try {
      const router = useRouter()

      const isOAuthUser = localStorage.getItem("amplify-signin-with-hostedUI")
      if (isOAuthUser === "true") {
        await Auth.signOut()
        dispatch(authEnd())
      } else {
        dispatch(partyClearing())
        await Auth.signOut()
        dispatch(authEnd())
        if (navigateHome) {
          router.push("/")
        }
      }
    } catch (err) {
      console.log(err)
      dispatch(authFail(err))
    }
  }

export const forgotPassword = (email) => async () => {
  try {
    const result = await Auth.forgotPassword(email)
    return result
  } catch (err) {
    console.log(err)
    return err
  }
}

export const forgotPasswordVerify = (email, code, new_password) => async () => {
  try {
    const result = await Auth.forgotPasswordSubmit(email, code, new_password)
    return result
  } catch (err) {
    console.log(err)
    return err
  }
}

export const lockSite = (isSiteLocked) => {
  return {
    type: actionTypes.AUTH_LOCK_SITE,
    isSiteLocked,
  }
}
