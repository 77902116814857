import * as actionTypes from "./actions/actionTypes"

const initialState = {
  parameters: {},
  paramProduct: {},
  paramMoodboard: {},
  selectMoodboard: {},
  imagesSelectedMoodboard: {},
  isUpdated: false,
  hasLoadedBrowserLocation: false,
}

const search = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.BROWSER_LOCATION_LOADED:
      return { ...state, hasLoadedBrowserLocation: action.hasLoadedBrowserLocation }
    case actionTypes.PARAM_LOAD:
      return { ...state, parameters: action.parameters }
    case actionTypes.PARAM_PRODUCT_LOAD:
      return { ...state, paramProduct: action.paramProduct }
    case actionTypes.PARAM_MOODBOARD_LOAD:
      return { ...state, paramMoodboard: action.paramMoodboard }
    case actionTypes.SELECT_MOODBOARD_LOAD:
      return { ...state, selectMoodboard: action.selectMoodboard }
    case actionTypes.IMAGES_SELECTED_MOODBOARD_LOAD:
      return { ...state, imagesSelectedMoodboard: action.imagesSelectedMoodboard }
    case actionTypes.PARAM_LOADED:
      return { ...state, isUpdated: action.isUpdated }
    default:
      return state
  }
}

export default search
