import { Auth, Cache, Hub } from "aws-amplify"
import { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "next/router"
import * as actions from "./actions/index"
import { eventGtag } from "@lib/gtag"
import hashCode from "@lib/hashCode"
import trackFBEvent from "@lib/trackFBEvent"
import ReactPinterestTag from "@lib/trackPintrest"
import lambdas from "@data/lambdas"

const { PARTY_SUPPLIER_SUBMIT, PARTY_SUBMIT } = lambdas[process.env.NEXT_PUBLIC_ENV]
const newPartyKey = hashCode("hoppNewParty")
const newSupplierKey = hashCode("hoppNewSupplier")

class AuthWrapper extends Component {
  checkStorageForParty = async () => {
    const newParty = Cache.getItem(newPartyKey)
    const newSupplier = Cache.getItem(newSupplierKey)

    if (newParty) {
      await this.createParty(newParty, newSupplier)
    }
  }

  //entry point in app
  async componentDidMount() {
    //init pintrest tag
    ReactPinterestTag.init("2613508627012")

    //see if user has navigated from social login and if there are any suppliers or parties to save in storage
    let hubEventFired = false
    const newParty = Cache.getItem(newPartyKey)
    const newSupplier = Cache.getItem(newSupplierKey)

    Hub.listen("auth", ({ payload: { event, data } }) => {
      hubEventFired = true
      switch (event) {
        case "signIn":
          break
        case "signOut":
          break
        case "customOAuthState":
          if (data === "signInCreateParty") {
            this.loadSession()
            this.createParty(newParty, newSupplier)
          }
          break
        default:
          break
      }
    })

    if (!hubEventFired) {
      await this.loadSession()
      await this.checkStorageForParty()
    }
  }

  componentDidUpdate = async (prevPros) => {
    if (prevPros.isAuthenticated !== this.props.isAuthenticated) {
      await this.checkStorageForParty()
    }
  }

  loadSession = async () => {
    try {
      await this.props.authCheck()
    } catch (error) {
      console.warn(error)
    }
  }

  createParty = async (party, supplier) => {
    if (this.props.isAuthenticated) {
      try {
        const user = await Auth.currentSession()

        const response = await fetch(PARTY_SUBMIT, {
          method: "POST",
          body: JSON.stringify({
            sessionToken: user.getIdToken().getJwtToken(),
            data: party,
          }),
        })

        if (response.ok) {
          const data = await response.json()
          await this.props.partyUpdate(data.party)

          //analytics
          const { categoryOptions } = this.props.staticData
          const categoryData = categoryOptions.find((category) => category.value === data.party.partyCategory)
          eventGtag({
            category: "Party",
            action: "Create",
            label: categoryData ? categoryData.label : "",
            value: data.party.partyId,
          })

          if (data.party) {
            this.addSupplier(data.party, supplier)
          }
        } else {
          alert(
            "There has been an error, please refresh the page and try again. If it persists please contact us at supplier@houseofpartyplanning.com"
          )
        }
      } catch (err) {
        console.log(err)
        alert(
          "There has been an error, please refresh the page and try again. If it persists please contact us at supplier@houseofpartyplanning.com"
        )
      }
    }
  }

  addSupplier = async (party, supplier) => {
    try {
      const data = {
        createdAt: party.partyId,
        supplierId: supplier,
        isAdding: true,
      }
      const user = await Auth.currentSession()
      const response = await fetch(PARTY_SUPPLIER_SUBMIT, {
        method: "POST",
        body: JSON.stringify({
          sessionToken: user.getIdToken().getJwtToken(),
          data: data,
        }),
      })
      if (response.ok) {
        const data = await response.json()
        this.props.partyUpdate({ ...party, ...data.result.Attributes })

        //analytics
        eventGtag({
          category: "Supplier",
          action: "Save",
          value: supplier,
        })
        trackFBEvent("AddToWishlist")

        Cache.removeItem(newPartyKey)
        Cache.removeItem(newSupplierKey)
        this.props.router.push(`/party/${party.partyId}`)
      } else {
        alert(
          "There has been an error, please refresh the page and try again. If it persists please contact us at supplier@houseofpartyplanning.com"
        )
      }
    } catch (err) {
      console.log(err)
      alert(
        "There has been an error, please refresh the page and try again. If it persists please contact us at supplier@houseofpartyplanning.com"
      )
    }
  }

  render() {
    return this.props.children
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    staticData: state.staticData.data,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    authCheck: () => dispatch(actions.authCheck()),
    partyLoadFirst: () => dispatch(actions.partyLoadFirst()),
    partyUpdate: (party) => dispatch(actions.partyUpdate(party)),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthWrapper))
