export default async (endpoint, body, errorCallback = null) => {
  try {
    const response = await fetch(endpoint, {
      method: "POST",
      body: JSON.stringify(body),
    })
    const data = await response.json()
    if (response.ok) {
      return { ok: response.ok, data: { ...data } }
    }
    if (errorCallback) {
      errorCallback(data)
    } else {
      alert(
        "There has been an error, please refresh the page and try again. If it persists please contact us at supplier@houseofpartyplanning.com"
      )
    }
    return { ok: response.ok, data: { ...data } }
  } catch (err) {
    console.log(err)
    if (errorCallback) {
      errorCallback(err)
    } else {
      alert(
        "There has been an error, please refresh the page and try again. If it persists please contact us at supplier@houseofpartyplanning.com"
      )
    }
    return false
  }
}
