import * as actionTypes from "./actions/actionTypes"

const initialState = {
  data: {
    subServiceOptions: [],
    serviceOptions: [],
    serviceCategoryOptions: [],
    categoryOptions: [],
    countyOptions: [],
    styleOptions: [],
    productBrandOptions: [],
    productCategoryOptions: [],
    categoryOptions: [],
    serviceTagOptions: [],
    settingOptions: [],
  },
}

const staticData = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DATA_LOAD:
      return { ...state, data: action.data }
    default:
      return state
  }
}

export default staticData
