import { Auth } from "aws-amplify"
import Cookies from "js-cookie"
import fetchLambda from "@data/fetchLambda"
import lambdas from "@data/lambdas"

const { SEND_FB_EVENT } = lambdas[process.env.NEXT_PUBLIC_ENV]

const trackFBEvent = async (event, customData = {}) => {
  // dont run on dev
  if (process.env.NEXT_PUBLIC_ENV !== "live") {
    return null
  }

  const userEmail = customData.user_email
  const contentCategory = customData.content_category

  // prevent ssr error
  if (typeof window !== "undefined") {
    const data = {
      sourceUrl: window.location.href,
      eventType: event,
    }

    if (userEmail) {
      data.userEmail = userEmail
    } else {
      let user = null

      try {
        user = await Auth.currentAuthenticatedUser({
          bypassCache: true,
        })
      } catch (error) {
        console.log(error)
      }

      if (user) {
        data.userEmail = user.attributes.email
      }
    }

    const fbp = Cookies.get("_fbp")
    const fbc = Cookies.get("_fbc")

    if (fbp) {
      data.fbp = fbp
    }
    if (fbc) {
      data.fbc = fbc
    }

    if (contentCategory) {
      data.contentCategory = contentCategory
    }

    await fetchLambda(SEND_FB_EVENT, {
      data,
    })
  }
}

export default trackFBEvent
