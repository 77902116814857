import * as actionTypes from "./actions/actionTypes"

const initialState = {
  isAuthenticated: undefined,
  isAuthenticating: false,
  userAttributes: {},
  userGroups: [],
  isSiteLocked: false,
  error: null,
}

const auth = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return { ...state, isAuthenticating: true }
    case actionTypes.AUTH_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        isAuthenticating: false,
        userAttributes: action.attributes,
        userGroups: action.groups,
        isSiteLocked: false,
      }
    case actionTypes.AUTH_FAIL:
      return {
        ...state,
        isAuthenticated: false,
        isAuthenticating: false,
        error: action.authData,
        userAttributes: {},
        userGroups: [],
      }
    case actionTypes.AUTH_END:
      return {
        ...state,
        isAuthenticated: false,
        isAuthenticating: false,
        userAttributes: {},
        userGroups: [],
      }
    case actionTypes.AUTH_LOCK_SITE:
      return {
        ...state,
        isSiteLocked: action.isSiteLocked,
      }
    default:
      return state
  }
}

export default auth
