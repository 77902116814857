import { Auth } from "aws-amplify"
import fetchLambda from "@data/fetchLambda"
import lambdas from "@data/lambdas"

const { USER_STATS_REQUEST } = lambdas[process.env.NEXT_PUBLIC_ENV]

const trackUser = async (action) => {
  // dont run on dev
  if (process.env.NODE_ENV !== "production") {
    return null
  }

  const user = await Auth.currentSession()
  if (!user) {
    return null
  }
  await fetchLambda(USER_STATS_REQUEST, {
    token: user.getIdToken().getJwtToken(),
    action,
  })
}

export default trackUser
