import { combineReducers } from "redux"
import auth from "./auth"
import party from "./party"
import search from "./search"
import staticData from "./staticData"

export default combineReducers({
  auth,
  party,
  staticData,
  search,
})
