const lambdas = []

lambdas['dev'] = {
    SUPPLIER_SUBMIT: "https://0zw5kmttg1.execute-api.eu-west-1.amazonaws.com/dev/hopp/createSupplier",
    SUPPLIER_SUBMIT_FINAL: "https://utox1kcovc.execute-api.eu-west-1.amazonaws.com/dev/hopp/reviewSupplier",
    SUPPLIER_SEARCH: "https://ba6q600xm4.execute-api.eu-west-1.amazonaws.com/dev/hopp/searchSupplier",
    STRIPE_GET_USER_SUBSCRIPTION_DETAILS: "https://cwk7crmd5b.execute-api.eu-west-1.amazonaws.com/dev/stripe/getPlan",
    STRIPE_REQUEST: "https://cc1ow6lhfh.execute-api.eu-west-1.amazonaws.com/dev/stripe/request",
    STRIPE_PAY: "https://97b5hnqd5k.execute-api.eu-west-1.amazonaws.com/dev/stripe/pay",
    STRIPE_ACTIVATE: "https://kanink5h6l.execute-api.eu-west-1.amazonaws.com/dev/stripe/activate",
    PARTY_SUBMIT: "https://ozhyl31u53.execute-api.eu-west-1.amazonaws.com/dev/hopp/createParty",
    PARTY_SUPPLIER_SUBMIT: "https://r3b3nuikjc.execute-api.eu-west-1.amazonaws.com/dev/hopp/createPartySupplier",
    PARTY_GET: "https://58osmt3a35.execute-api.eu-west-1.amazonaws.com/dev/hopp/getParty",
    PARTY_DELETE: "https://tepgabjij0.execute-api.eu-west-1.amazonaws.com/dev/hopp/deleteParty",
    REVIEW_SUBMIT: "https://0edld4mrbc.execute-api.eu-west-1.amazonaws.com/dev/hopp/createReview",
    GET_SUPPLIER_FORM_SUBMISSION: "https://nt7p2zv4pd.execute-api.eu-west-1.amazonaws.com/dev/hopp/getSupplier",
    IMAGE_UPLOAD: "https://rokwhks1s0.execute-api.eu-west-1.amazonaws.com/dev/hopp/upload",
    IMAGE_UPLOAD_URL: "https://qrhj8iizxk.execute-api.eu-west-1.amazonaws.com/dev/hopp/upload",
    BLOG_GET: "https://prma1926p4.execute-api.eu-west-1.amazonaws.com/dev/hopp/getBlog",
    ENQUIRY_SUBMIT: "https://glfwxj64h0.execute-api.eu-west-1.amazonaws.com/dev/hopp/sendEnquiryEmail",
    MAILCHIMP_REQUEST: "https://qft89l8kte.execute-api.eu-west-1.amazonaws.com/dev/hopp/mailchimpRequest",
    COGNITO_REQUEST: "https://tux1bqkvuf.execute-api.eu-west-1.amazonaws.com/dev/hopp/cognitoRequest",
    SEND_EMAIL: "https://obdue54562.execute-api.eu-west-1.amazonaws.com/dev/hopp/sendEmail",
    APPROVE_SUPPLIER: "https://z5t3cjym8i.execute-api.eu-west-1.amazonaws.com/dev/hopp/approveSupplier",
    DATO_REQUEST: "https://bku54egjpf.execute-api.eu-west-1.amazonaws.com/dev/hopp/datoRequest",
    TAG_PHOTOS: "https://nmqa14a2g3.execute-api.eu-west-1.amazonaws.com/dev/hopp/tagPhotos",
    IMAGE_SEARCH: "https://j06plshm30.execute-api.eu-west-1.amazonaws.com/dev/hopp/imageSearch",
    PRODUCT_SEARCH: "https://2a6lfpjdz1.execute-api.eu-west-1.amazonaws.com/dev/hopp/productSearch",
    SEND_FB_EVENT: "https://kqrcd4z597.execute-api.eu-west-1.amazonaws.com/dev/hopp/sendFbEvent",
    USER_STATS_REQUEST: "https://ruik10bdm3.execute-api.eu-west-1.amazonaws.com/dev/hopp/userStatsRequest",
    DYNAMO_REQUEST: "https://c1jgq630u5.execute-api.eu-west-1.amazonaws.com/dev/hopp/dynamoRequest"
}

lambdas['test'] = {
    SUPPLIER_SUBMIT: "https://1fcxrix3xl.execute-api.eu-west-1.amazonaws.com/test/hopp/createSupplier",
    SUPPLIER_SUBMIT_FINAL: "https://49m7udsk02.execute-api.eu-west-1.amazonaws.com/test/hopp/reviewSupplier",
    SUPPLIER_SEARCH: "https://isrh6eluqd.execute-api.eu-west-1.amazonaws.com/test/hopp/searchSupplier",
    STRIPE_GET_USER_SUBSCRIPTION_DETAILS: "https://umb9sx5t1c.execute-api.eu-west-1.amazonaws.com/test/stripe/getPlan",
    STRIPE_REQUEST: "https://1uinejmin7.execute-api.eu-west-1.amazonaws.com/test/stripe/request",
    STRIPE_PAY: "https://l71dp1pwgj.execute-api.eu-west-1.amazonaws.com/test/stripe/pay",
    STRIPE_ACTIVATE: "https://dca3mqj0pa.execute-api.eu-west-1.amazonaws.com/test/stripe/activate",
    PARTY_SUBMIT: "https://xtk5az4stg.execute-api.eu-west-1.amazonaws.com/test/hopp/createParty",
    PARTY_SUPPLIER_SUBMIT: "https://rez2aw0uc9.execute-api.eu-west-1.amazonaws.com/test/hopp/createPartySupplier",
    PARTY_GET: "https://imffbj8v1d.execute-api.eu-west-1.amazonaws.com/test/hopp/getParty",
    PARTY_DELETE: "https://u45bw4gvhg.execute-api.eu-west-1.amazonaws.com/test/hopp/deleteParty",
    REVIEW_SUBMIT: "https://w9okaxevv4.execute-api.eu-west-1.amazonaws.com/test/hopp/createReview",
    GET_SUPPLIER_FORM_SUBMISSION: "https://4kfu893swb.execute-api.eu-west-1.amazonaws.com/test/hopp/getSupplier",
    IMAGE_UPLOAD: "https://2jq8ck34e3.execute-api.eu-west-1.amazonaws.com/test/hopp/upload",
    IMAGE_UPLOAD_URL: "https://fmg4xcxoh6.execute-api.eu-west-1.amazonaws.com/test/hopp/upload",
    BLOG_GET: "https://9ch2330645.execute-api.eu-west-1.amazonaws.com/test/hopp/getBlog",
    ENQUIRY_SUBMIT: "https://sa75igvioj.execute-api.eu-west-1.amazonaws.com/test/hopp/sendEnquiryEmail",
    MAILCHIMP_REQUEST: "https://i4nd4hbrih.execute-api.eu-west-1.amazonaws.com/test/hopp/mailchimpRequest",
    COGNITO_REQUEST: "https://p9frn41ka8.execute-api.eu-west-1.amazonaws.com/test/hopp/cognitoRequest",
    SEND_EMAIL: "https://o4zx8ahjpd.execute-api.eu-west-1.amazonaws.com/test/hopp/sendEmail",
    APPROVE_SUPPLIER: "https://z4z8st0gs8.execute-api.eu-west-1.amazonaws.com/test/hopp/approveSupplier",
    DATO_REQUEST: "https://djss8w5lca.execute-api.eu-west-1.amazonaws.com/test/hopp/datoRequest",
    TAG_PHOTOS: "https://ufm4d4utsh.execute-api.eu-west-1.amazonaws.com/test/hopp/tagPhotos",
    IMAGE_SEARCH: "https://8waeh96nrc.execute-api.eu-west-1.amazonaws.com/test/hopp/imageSearch",
    PRODUCT_SEARCH: "https://7iw3ykuy2j.execute-api.eu-west-1.amazonaws.com/test/hopp/productSearch",
    SEND_FB_EVENT: "https://3d9zn3pxbh.execute-api.eu-west-1.amazonaws.com/live/hopp/sendFbEvent",
    USER_STATS_REQUEST: "https://arw0x0qgqf.execute-api.eu-west-1.amazonaws.com/test/hopp/userStatsRequest",
    DYNAMO_REQUEST: "https://c1jgq630u5.execute-api.eu-west-1.amazonaws.com/dev/hopp/dynamoRequest"
}

lambdas['production'] = {
    SUPPLIER_SUBMIT: "https://tr89p5v734.execute-api.eu-west-1.amazonaws.com/live/hopp/createSupplier",
    SUPPLIER_SUBMIT_FINAL: "https://3utmyd4u77.execute-api.eu-west-1.amazonaws.com/live/hopp/reviewSupplier",
    SUPPLIER_SEARCH: "https://xou37dpie4.execute-api.eu-west-1.amazonaws.com/live/hopp/searchSupplier",
    STRIPE_GET_USER_SUBSCRIPTION_DETAILS: "https://iemn9hrerf.execute-api.eu-west-1.amazonaws.com/live/stripe/getPlan",
    STRIPE_REQUEST: "https://5ch6mo1z8k.execute-api.eu-west-1.amazonaws.com/live/stripe/request",
    STRIPE_PAY: "https://z6v37636xb.execute-api.eu-west-1.amazonaws.com/live/stripe/pay",
    STRIPE_ACTIVATE: "https://i47be2ibnd.execute-api.eu-west-1.amazonaws.com/live/stripe/activate",
    PARTY_SUBMIT: "https://ki7hmbmms4.execute-api.eu-west-1.amazonaws.com/live/hopp/createParty",
    PARTY_SUPPLIER_SUBMIT: "https://4l3cx7itx9.execute-api.eu-west-1.amazonaws.com/live/hopp/createPartySupplier",
    PARTY_GET: "https://hddux216wi.execute-api.eu-west-1.amazonaws.com/live/hopp/getParty",
    PARTY_DELETE: "https://boi7oh9q6b.execute-api.eu-west-1.amazonaws.com/live/hopp/deleteParty",
    REVIEW_SUBMIT: "https://nbnn8v9xug.execute-api.eu-west-1.amazonaws.com/live/hopp/createReview",
    GET_SUPPLIER_FORM_SUBMISSION: "https://f3o2kzbh1e.execute-api.eu-west-1.amazonaws.com/live/hopp/getSupplier",
    IMAGE_UPLOAD: "https://7ysx7sv21g.execute-api.eu-west-1.amazonaws.com/live/hopp/upload",
    IMAGE_UPLOAD_URL: "https://ej1x9oz360.execute-api.eu-west-1.amazonaws.com/live/hopp/upload",
    BLOG_GET: "https://yeu35uw1d5.execute-api.eu-west-1.amazonaws.com/live/hopp/getBlog",
    ENQUIRY_SUBMIT: "https://bimwyhm3zb.execute-api.eu-west-1.amazonaws.com/live/hopp/sendEnquiryEmail",
    MAILCHIMP_REQUEST: "https://enpekvigwc.execute-api.eu-west-1.amazonaws.com/live/hopp/mailchimpRequest",
    COGNITO_REQUEST: "https://1q18l6kg1d.execute-api.eu-west-1.amazonaws.com/live/hopp/cognitoRequest",
    SEND_EMAIL: "https://pjpnlgl4fj.execute-api.eu-west-1.amazonaws.com/live/hopp/sendEmail",
    APPROVE_SUPPLIER: "https://2pvk7bs12i.execute-api.eu-west-1.amazonaws.com/live/hopp/approveSupplier",
    DATO_REQUEST: "https://ggfkwzkkih.execute-api.eu-west-1.amazonaws.com/live/hopp/datoRequest",
    TAG_PHOTOS: "https://jataz2x1sj.execute-api.eu-west-1.amazonaws.com/live/hopp/tagPhotos",
    IMAGE_SEARCH: "https://80g82iwuo5.execute-api.eu-west-1.amazonaws.com/live/hopp/imageSearch",
    PRODUCT_SEARCH: "https://iz2aclclr1.execute-api.eu-west-1.amazonaws.com/live/hopp/productSearch",
    SEND_FB_EVENT: "https://3d9zn3pxbh.execute-api.eu-west-1.amazonaws.com/live/hopp/sendFbEvent",
    USER_STATS_REQUEST: "https://wt23we90n7.execute-api.eu-west-1.amazonaws.com/live/hopp/userStatsRequest",
    DYNAMO_REQUEST: "https://ta0iiy1tta.execute-api.eu-west-1.amazonaws.com/live/hopp/dynamoRequest"
}

export default lambdas